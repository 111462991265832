import { useStaticQuery, graphql } from 'gatsby';

export const useLocationInfo = () => {
    const locationInfo = useStaticQuery(
        graphql`
            query GET_LOCATION_INFO {
                allWordpressAcfOptions {
                    nodes {
                        options {
                            get_directions_link
                            map_logo {
                                localFile {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                        }
                                        fixed {
                                            ...GatsbyImageSharpFixed
                                        }
                                    }
                                }
                            }
                            map_background_image {
                                localFile {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                        }
                                        fixed {
                                            ...GatsbyImageSharpFixed
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return locationInfo.allWordpressAcfOptions.nodes[0].options;
};
