import { useStaticQuery, graphql } from 'gatsby';

export const useLatestBlog = () => {
    const LatestPosts = useStaticQuery(
        graphql`
            query GET_LATEST_POSTS {
                allWordpressPost(limit: 5) {
                    nodes {
                        featured_media {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                    fixed {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                        categories {
                            name
                        }
                        date
                        excerpt
                        slug
                        title
                        id
                    }
                }
            }
        `
    );
    return LatestPosts;
};
