import { useStaticQuery, graphql } from 'gatsby';

export const useTestimonials = () => {
    const HomeTestimonials = useStaticQuery(
        graphql`
            query GET_HOME_TESTIMONIALS_CONTENT {
                wordpressPage(wordpress_id: { eq: 5 }) {
                    acf {
                        what_parents_say_list {
                            parents_testimonial
                            parents_name
                        }
                        what_parents_say_image {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                    fixed {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return HomeTestimonials;
};
