import { useStaticQuery, graphql } from 'gatsby';

export const useHomeSeo = () => {
    const HomeSeo = useStaticQuery(
        graphql`
            query GET_HOME_SEO {
                wordpressPage(wordpress_id: { eq: 5 }) {
                    yoast_title
                    yoast_meta {
                        name
                        property
                    }
                }
            }
        `
    );
    return HomeSeo;
};
