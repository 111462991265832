import { useStaticQuery, graphql } from 'gatsby';

export const useEventsHome = () => {
    const eventsHome = useStaticQuery(
        graphql`
            query GET_EVENTS_HOME {
                allWordpressWpEventsPostType(limit: 3) {
                    nodes {
                        slug
                        title
                        id
                        featured_media {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                    fixed {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                        acf {
                            event_date
                        }
                    }
                }
            }
        `
    );
    return eventsHome.allWordpressWpEventsPostType.nodes;
};
