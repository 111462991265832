import { useStaticQuery, graphql } from 'gatsby';

export const useAppContent = () => {
    const AppContent = useStaticQuery(
        graphql`
            query GET_HOMEPAGE_APP_CONTENT {
                wordpressPage(wordpress_id: { eq: 5 }) {
                    acf {
                        app_content
                        app_title
                        app_right_side_image {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        ...GatsbyImageSharpFluid
                                    }
                                    fixed {
                                        ...GatsbyImageSharpFixed
                                    }
                                }
                            }
                        }
                        app_non_profit_link
                        app_learn_more_link
                        app_download_text
                    }
                }
            }
        `
    );
    return AppContent;
};
