import React from 'react';
import { useLocationInfo } from '../../hooks/getLocationInfo';

const Map = () => {
    const locationData = useLocationInfo();
    return (
        <div className="mapwrap">
            <img
                className="map_bg"
                src={
                    locationData.map_background_image.localFile.childImageSharp
                        .fluid.src
                }
                srcSet={
                    locationData.map_background_image.localFile.childImageSharp
                        .fluid.srcSet
                }
                alt="map location"
                loading="lazy"
            />
            <h2 className="bluetxt">Our Location</h2>
            <img
                src={locationData.map_logo.localFile.childImageSharp.fluid.src}
                alt="map logo"
                loading="lazy"
            />
            <a
                href={locationData.get_directions_link}
                target="_blank"
                className="wrtsbtn bluebtn"
                rel="noopener noreferrer"
            >
                GET DIRECTIONS
            </a>
        </div>
    );
};
export default Map;
