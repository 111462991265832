import { useStaticQuery, graphql } from 'gatsby';

export const useStaffMembers = () => {
    const AllStaffMembers = useStaticQuery(
        graphql`
            query GET_ALL_STAFF_MEMBERS {
                allWordpressAcfOptions {
                    nodes {
                        options {
                            our_staff_list_members {
                                staff_member_fullname
                                staff_member_position
                                staff_member_image {
                                    localFile {
                                        childImageSharp {
                                            fluid {
                                                ...GatsbyImageSharpFluid
                                            }
                                            fixed {
                                                ...GatsbyImageSharpFixed
                                            }
                                        }
                                    }
                                }
                            }
                            our_staff_section_title
                        }
                    }
                }
            }
        `
    );
    return AllStaffMembers;
};
