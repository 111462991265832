import { useStaticQuery, graphql } from 'gatsby';

export const useHeroContent = () => {
    const HeroContent = useStaticQuery(
        graphql`
            query GET_HOMEPAGE_HERO_CONTENT {
                wordpressPage(wordpress_id: { eq: 5 }) {
                    acf {
                        heroSubtitle
                        heroButtonText
                        heroButtonLink
                        heroWelcomeTitle
                        hero_video_file_webp {
                            source_url
                        }
                        hero_video_file_mp4 {
                            source_url
                        }
                    }
                }
            }
        `
    );
    return HeroContent;
};
