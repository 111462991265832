import { useStaticQuery, graphql } from 'gatsby';

export const useServicesHome = () => {
    const servicesHome = useStaticQuery(
        graphql`
            query GET_SERVICES_HOME {
                wordpressPage(wordpress_id: { eq: 5 }) {
                    acf {
                        service_boxes_list {
                            service_description
                            service_title
                            service_link
                            service_image {
                                localFile {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                        }
                                        fixed {
                                            ...GatsbyImageSharpFixed
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    return servicesHome;
};
